import { Component, OnInit } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';


@Component({
  selector: 'app-word',
  templateUrl: './word.component.html',
  styleUrls: ['./word.component.css']
})
export class WordComponent {

  countAttempt = 0;

  randomList = new Array<english>();

  polishModelVisible = true;
  englishModelVisible = false;
  bothModelVisible = false;
  strategyModel = 2;


  nextWords() {
    this.countAttempt++;
    this.getUsers();
  }

  changeStrategy(stratId: number) {
    this.strategyModel = stratId;
  }

  getUsers() {
    let par = this.strategyModel.toString();
    const params = new HttpParams().set('strategy', par);

    this.http.get('https://apienglish.sceers.com/api/englishjob/getwords', { params: params }).subscribe(data => {

      this.randomList = [];
      for (const d of (data['english'] as any)) {
        this.randomList.push(
          {
            polishId: d.polishId,
            polishWord: d.polishWord,
            englishWord: d.englishWord
          }
        );
      }

    });

    }



//34
//https://www.youtube.com/watch?v=RP4UA9tXrAI&list=PLpzwMkmxJDUyD-UTGQXuevw0YOPgvroPL&index=25

//dodac przycisk zmieniajacy uklad na angielskie
//pokazujacy jedno i drugie

  constructor(private http: HttpClient) {
    this.getUsers();
   }

  addWord() {
  }



}

class english {
  constructor(public polishId: number, public polishWord: string, public englishWord: string) {
  }
}
